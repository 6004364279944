import pako from "pako";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import * as base64 from "js-base64";
import { SoccerStatSnapshot, Team } from "@ollie-sports/models";
import md5 from "md5";
import stableStringify from "json-stable-stringify";
import { flex__server__mysqlTest } from "./flex";
import { org__server__getOrgTeams } from "./org.api";
import type { Redis } from "ioredis";

export function common__magicDeleteValue() {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return h._MagicDeleteValue;
}

// Used when we need to generate an id on the client
export function common__client__getGenerator() {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return {
    AccountId: () => h.Account.generateId(),
    AccountPrivateId: () => h.AccountPrivate.generateId(),
    AccountSecretId: () => h.AccountSecret.generateId(),
    CalendarEntryId: () => h.CalendarEntry.generateId(),
    ChecklistItem: () => h.ChecklistItem.generateId(),
    CodeId: () => h.Code.generateId(),
    ConversationId: () => h.Conversation.generateId(),
    LicenseId: () => h.License.generateId(),
    LiveGameCustomEvent: () => h.LiveGameCustomEvent.generateId(),
    LiveGameReaction: () => h.LiveGameReaction.generateId(),
    TeamId: () => h.Team.generateId(),
    MessageId: () => h.Message.generateId(),
    OrgId: () => h.Org.generateId(),
    PlayerId: () => h.Player.generateId(),
    PlayerBundleId: () => h.PlayerBundle.generateId(),
    PlayerBundleNoteId: () => h.PlayerBundleNote.generateId(),
    PollId: () => h.Poll.generateId(),
    SoccerGameId: (calendarEntryId: string) => `soccer-game-${calendarEntryId}`,
    SoccerGameEventId: () => h.SoccerGameEvent.generateId(),
    SoccerStatSnapshotId: (soccerGameId: string, type: SoccerStatSnapshot["snapshotType"]) =>
      `soccer-stat-snapshot-${soccerGameId}-${type}`,
    Sponsor: () => h.Sponsor.generateId(),
    SoccerGameEventBundleId: (soccerGameId: string) => `soccer-game-event-bundle-${soccerGameId}`,
    SchoolId: () => h.School.generateId(),
    EvaluationId: () => h.Evaluation.generateId(),
    EvaluationTemplateId: () => h.EvaluationTemplate.generateId(),
    LibraryResourceId: () => h.LibraryResource.generateId(),
    ScoreKeepingEventId: () => h.ScoreKeepingEvent.generateId(),
    OrgTeamTagId: () => h.OrgTeamTag.generateId(),
    OrgInvoice: () => h.OrgInvoice.generateId(),
    OrgRegistration: () => h.OrgRegistration.generateId(),
    OrgRegistrationSuccessMessage: () => h.OrgRegistrationSuccessMessage.generateId(),
    OrgRegistrationPackage: () => h.OrgRegistrationPackage.generateId(),
    OrgRegistrationQuestion: () => h.OrgRegistrationQuestion.generateId(),
    OrgRegistrationQuestionGrouping: () => h.OrgRegistrationQuestionGrouping.generateId(),
    OrgRegistrationAnswer: () => h.OrgRegistrationAnswer.generateId(),
    OrgPaymentPlan: () => h.OrgPaymentPlan.generateId(),
    OrgPayment: () => h.OrgPayment.generateId()
  };
}

export function common__compressString(p: { str: string }) {
  return "BASE64-" + base64.encode(pako.deflate(p.str, { to: "string" }));
}

export function common__decompressString(p: { str: string }) {
  let str = p.str;
  if (str.substr(0, 7) === "BASE64-") {
    str = base64.decode(str.substr(7));
  }
  return pako.inflate(str, { to: "string" });
}

export function common__hashObject(p: { obj: any }) {
  return md5(stableStringify(p.obj));
}

export function common__generateTeamIdWithSquad(teamId: string, squad?: string) {
  return `${teamId}-${squad || "NONE"}`;
}

export function common__extractTeamIdAndSquadFromTeamIdWithSquad(teamIdWithSquad: string) {
  const indexOfDash = teamIdWithSquad.lastIndexOf("-");
  const teamId = teamIdWithSquad.slice(0, indexOfDash);
  const possibleSquad = teamIdWithSquad.slice(indexOfDash + 1);
  let squad: "a" | "b" | "c" | "NONE" = "NONE";
  if (possibleSquad === "NONE" || possibleSquad === "a" || possibleSquad === "b" || possibleSquad === "c") {
    squad = possibleSquad;
  }
  return { teamId, squad };
}

export function common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad(
  teamIdWithSquad: string,
  team: Team,
  shortSquadName: boolean
) {
  const { teamId, squad } = common__extractTeamIdAndSquadFromTeamIdWithSquad(teamIdWithSquad);
  if (squad === "a" || squad === "b" || squad === "c") {
    if (shortSquadName) {
      return `${team.name} - ${team.squads?.[squad]?.short || ""}`;
    } else {
      return `${team.name} - ${team.squads?.[squad]?.name || ""}`;
    }
  }
  return team.name;
}

export async function common__fetchServerSystemStatus() {
  // SERVER_ONLY_TOGGLE
  const { appFirebaseAdminApp, getAnalyticsPgPool, getAppPgPool, injectedServerLibraries } = getServerHelpers();
  const { ioredis } = injectedServerLibraries;

  const [
    firebaseConnectionStatus,
    pgConnectionStatus,
    analyticsPgConnectionStatus,
    coreExampleCodeExecution,
    redisConnectionStatus
  ] = await Promise.all([
    appFirebaseAdminApp
      .firestore()
      .collection("example")
      .doc("status-doc")
      .set({ foo: "bar", date: Date.now() })
      .then(
        () => true,
        (e: any) => {
          console.log("Problem fetching from firebase!", e);
          return `ERROR - ${e?.message}`;
        }
      ),
    getAppPgPool()
      .query(`select 'hello world' as hello`)
      .then(
        () => true,
        (e: any) => {
          console.error("Problem selecting from pg app pool!", e);
          return `ERROR - ${e.message}`;
        }
      ),

    getAnalyticsPgPool()
      .query(`select 'hello world' as hello`)
      .then(
        () => true,
        (e: any) => {
          console.log("Problem selecting form pg analytics pool!", e);
          return `ERROR - ${e?.message}`;
        }
      ),

    org__server__getOrgTeams({ orgId: "fake_org_id" }).then(
      () => true,
      e => {
        console.error("Problem getting org teams!", e);
        return `ERROR - ${e.message}`;
      }
    ),

    (async () => {
      let connection: Redis | null = null;
      try {
        connection = new ioredis.default({
          commandTimeout: 20000,
          connectTimeout: 20000
        });

        await connection.ping();
        return true;
      } catch (e) {
        console.error("Problem connecting to Redis!", e);
        throw e;
      } finally {
        await connection?.quit();
      }
    })().catch(e => `ERROR - ${e.message}`)
  ]);

  const healthy = [
    pgConnectionStatus,
    analyticsPgConnectionStatus,
    firebaseConnectionStatus,
    coreExampleCodeExecution,
    redisConnectionStatus
  ].every(a => a === true);

  return {
    healthy,
    requestDateMS: Date.now(),
    pgConnectionStatus,
    analyticsPgConnectionStatus,
    firebaseConnectionStatus,
    coreExampleCodeExecution,
    redisConnectionStatus
  };
  // SERVER_ONLY_TOGGLE
}

export async function findUnusedCode(): Promise<string> {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();

  //Returns 9 digit integer string
  const getRandomCodeString = () => {
    let code = "";
    for (let i = 0; i < 9; i++) {
      code += Math.floor(Math.random() * 10);
    }
    return code;
  };

  const doesCodeAlreadyExist = async (code: string) => {
    return (await h.Code.query({ where: [{ code: ["==", code] }] })).docs.length > 0;
  };

  let code: string;
  do {
    code = getRandomCodeString();
  } while (await doesCodeAlreadyExist(code));

  return code;
  // SERVER_ONLY_TOGGLE
}
// i18n certified - complete
